import React, { useState } from "react";
import { colors } from "../../../styles/colors";

export const OptionsEditComponent = ({ text, data }) => {
  // Estado para almacenar el botón seleccionado
  const [selectedOption, setSelectedOption] = useState(null);

  // Función para manejar el click en un botón
  const handleClick = (info) => {
    setSelectedOption(info);
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ width: "100%" }}
    >
      <p className="text-options-edit mt-1">{text}</p>
      <div
        className="d-flex align-items-center justify-content-center flex-wrap mt-2"
        style={{ width: "100%" }}
      >
        {data?.map((info, index) => (
          <>
            {info?.enabled === "no" ? (
              <button
                key={index}
                onClick={() => handleClick(info.name)} // Al hacer click, se guarda el valor
                className="btn-option-edit mb-2 me-2"
                style={{
                  width: info?.name?.length > 10 ? "auto" : "115px",
                  backgroundColor: colors.gray.lightGrey,
                  border: `1px solid ${colors.gray.disabled}`,
                  color: colors.gray.disabled,
                }}
              >
                {info.name}
              </button>
            ) : (
              <button
                key={index}
                onClick={() => handleClick(info.name)} // Al hacer click, se guarda el valor
                className="btn-option-edit mb-2 me-2"
                style={{
                  width: info?.name?.length > 10 ? "auto" : "115px",
                  backgroundColor:
                    selectedOption === info.name
                      ? colors.purple.purple
                      : colors.purple.utraLightPurple,
                  border: `1px solid ${
                    selectedOption === info.name
                      ? colors.purple.darkPurple
                      : colors.purple.lightPurple
                  }`,
                  color:
                    selectedOption === info.name
                      ? colors.white.white
                      : colors.purple.darkPurple,
                }}
              >
                {info.name}
              </button>
            )}
          </>
        ))}
      </div>
    </div>
  );
};
