import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { es } from "date-fns/locale";
import ButtonComponent from "../../../components/ButtonComponent";
import { eachDayOfInterval, isSameDay } from "date-fns";
import { DescriptionsDayComponent } from "./DescriptionsDayComponent";
import { colors } from "../../../styles/colors";

const CalendarComponent = ({
  settingsMonth,
  setSettingsMonth,
  setActiveEditDays,
  setActiveSlotInspect,
  setEditMonth,
  setSelectedDay,
  setIsHoliday,
}) => {
  // Definir fechas para los días feriados laborales y no laborales, y día deshabilitado
  const laborables = [
    new Date(2024, 8, 3),
    new Date(2024, 8, 14),
    new Date(2024, 9, 1),
    new Date(2024, 9, 10),
  ]; // Septiembre 3, 10; Octubre 1 y 10
  const noLaborables = [
    new Date(2024, 8, 17),
    new Date(2024, 8, 18),
    new Date(2024, 9, 14),
    new Date(2024, 9, 30),
  ]; // Septiembre 17, 24; Octubre 14 y 30
  const disabledDays = [new Date(2024, 9, 31)]; // Octubre 31 (día deshabilitado)

  // Seleccionar todos los días de septiembre y octubre
  const startSeptember = new Date(2024, 8, 1); // Primer día de septiembre
  const endOctober = new Date(2024, 9, 31); // Último día de octubre
  const allDaysOfPeriod = eachDayOfInterval({
    start: startSeptember,
    end: endOctober,
  });

  // Filtrar días seleccionados: todos los días de septiembre y octubre excepto feriados y deshabilitados
  const selectedDays = allDaysOfPeriod.filter(
    (day) =>
      !laborables.some((l) => isSameDay(day, l)) &&
      !noLaborables.some((nl) => isSameDay(day, nl)) &&
      !disabledDays.some((dd) => isSameDay(day, dd))
  );

  // Modificadores para colorear días
  const modifiers = {
    laborables,
    noLaborables,
    disabled: disabledDays,
  };

  // Estilos personalizados para cada tipo de día
  const modifiersStyles = {
    laborables: {
      backgroundColor: "#D7C9EE", // Rosa para feriados laborales
      borderRadius: "50%",
    },
    noLaborables: {
      backgroundColor: "#E8E1EF", // Rosa para feriados no laborales
      borderRadius: "50%",
    },
    disabled: {
      color: "#D7C9EE", // Gris para días deshabilitados
    },
  };

  // Función para manejar el clic en un día
  const handleDayClick = (day) => {
    if (!settingsMonth) {
      setActiveSlotInspect(true);
      setSettingsMonth(true);
      setEditMonth(true);
    }

    setSelectedDay(day); // Guardar el día seleccionado

    // Verificar si es un día feriado
    if (laborables.some((l) => isSameDay(day, l))) {
      setIsHoliday("Feriado Laborable");
    } else if (noLaborables.some((nl) => isSameDay(day, nl))) {
      setIsHoliday("Feriado No Laborable");
    } else {
      setIsHoliday("No es feriado");
    }
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ width: "100%" }}
    >
      <DayPicker
        mode="multiple"
        locale={es}
        selected={selectedDays} // Seleccionar todos los días de septiembre y octubre, excluyendo feriados y deshabilitados
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        onDayClick={handleDayClick} // Manejar el clic en un día
        disabled={disabledDays} // Deshabilitar el día específico
      />
      <div
        className="d-flex align-items-center justify-content-between mt-3 p-2"
        style={{ width: "100%" }}
      >
        <DescriptionsDayComponent
          color={colors.purple.purple}
          text={"Día laborable"}
        />
        <DescriptionsDayComponent
          color={colors.purple.lightPurple}
          text={"Feriado Laborable"}
        />
        <DescriptionsDayComponent
          color={colors.purple.utraLightPurple}
          text={"Feriado No Laborable"}
        />
      </div>

      {settingsMonth ? (
        <div className="d-flex align-items-center justify-content-center mt-4">
          <ButtonComponent
            text={"Volver"}
            color={"white"}
            handleEvent={() => {
              setSettingsMonth(false);
              setEditMonth(true);
            }}
          />
          <ButtonComponent
            text={"Editar"}
            color={"purple"}
            handleEvent={() => {
              setActiveEditDays(true);
              setEditMonth(false);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CalendarComponent;
