import React, { useState } from "react";
import CardComponent from "../../components/CardComponent";
import ButtonsHeaderComponent from "./components/ButtonsHeaderComponent";
import CalendarComponent from "./components/CalendarComponent";
import "./style/style.css";
import EditDaysComponent from "./components/EditDaysComponent";
import SlotInspectComponent from "./components/SlotInspectComponent";
import { EditMonthComponent } from "./components/EditMonthComponent";

const AppointmentPage = () => {
  const [settingsMonth, setSettingsMonth] = useState(false);
  const [activeEditDays, setActiveEditDays] = useState(false);
  const [activeSlotInspect, setActiveSlotInspect] = useState(false);
  const [activeSlot, setActiveSlot] = useState(false);
  const [editMonth, setEditMonth] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isHoliday, setIsHoliday] = useState(null);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {!editMonth && !settingsMonth ? (
        <ButtonsHeaderComponent setEditMonth={setEditMonth} />
      ) : null}
      <CardComponent
        title={
          activeSlotInspect && !activeSlot
            ? "Slot Inspección"
            : activeSlot && activeSlotInspect
            ? "Editar slot"
            : editMonth
            ? "Editar mes"
            : settingsMonth && !activeEditDays
            ? "Seleccionar día/s a editar"
            : activeEditDays
            ? "Editar día/s"
            : "Calendario"
        }
      >
        <div className="d-flex align-items-center justify-content-center flex-column mt-4">
          {activeEditDays ? (
            <EditDaysComponent
              setActiveEditDays={setActiveEditDays}
              setSettingsMonth={setSettingsMonth}
            />
          ) : activeSlotInspect ? (
            <SlotInspectComponent
              activeSlot={activeSlot}
              setActiveSlot={setActiveSlot}
              setActiveSlotInspect={setActiveSlotInspect}
              setSettingsMonth={setSettingsMonth}
              setEditMonth={setEditMonth}
              isHoliday={isHoliday}
            />
          ) : editMonth ? (
            <EditMonthComponent
              setSettingsMonth={setSettingsMonth}
              setEditMonth={setEditMonth}
            />
          ) : (
            <CalendarComponent
              settingsMonth={settingsMonth}
              setSettingsMonth={setSettingsMonth}
              setActiveEditDays={setActiveEditDays}
              setActiveSlotInspect={setActiveSlotInspect}
              setEditMonth={setEditMonth}
              setSelectedDay={setSelectedDay}
              setIsHoliday={setIsHoliday}
            />
          )}
        </div>
      </CardComponent>
    </div>
  );
};

export default AppointmentPage;
