import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import AppointmentPage from "../modules/Appointment/AppointmentPage";
import InspectorsPage from "../modules/Inspectors/InspectorsPage";
import LocationsPage from "../modules/Locations/LocationsPage";
import HomePage from "../modules/Home/HomePage";
import ContainerAppComponent from "../components/ContainerAppComponent";
import LoginPage from "../modules/Auth/LoginPage";
import { useDispatch } from "react-redux";
import { auth } from "../firebase/config";
import { login } from "../redux/actions/auth";
import { onAuthStateChanged } from "firebase/auth";
import PrivateRoute from "./PrivateRoute";

export default function AppRouter() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.uid) {
        dispatch(
          login(
            user.uid,
            user.displayName ? user.displayName : "",
            user.email,
            true
          )
        );
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ContainerAppComponent />}>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="turnos"
            element={
              <PrivateRoute>
                <AppointmentPage />
              </PrivateRoute>
            }
          />
          <Route
            path="inspectores-vendedores"
            element={
              <PrivateRoute>
                <InspectorsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="sucursales"
            element={
              <PrivateRoute>
                <LocationsPage />
              </PrivateRoute>
            }
          />
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Routes>
    </Router>
  );
}
