import React from "react";
import { Form, Modal } from "react-bootstrap";
import "../styles/components/modal.css";
import IconClose from "../images/icon-close.svg";
import ButtonComponent from "./ButtonComponent";

const ModalCheckboxComponent = ({ isOpen, onClose, title }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered size="sm">
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-end header-modal-checkbox">
            <div
              className="d-flex align-items-center justify-content-center ms-2"
              style={{ width: "100%" }}
            >
              <p className="title-header-modal-checkbox">Seleccionar {title}</p>
            </div>
            <img
              src={IconClose}
              alt="icon-close"
              className="me-2"
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </div>
          <div
            className="d-flex align-items-start justify-content-center flex-column mt-3"
            style={{ width: "100%" }}
          >
            {title === "sucursal" ? (
              <>
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  label="Av. Independencia 3050, CABA"
                />
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  label="Av. Warnes 2707, CABA"
                />
              </>
            ) : (
              <>
                <Form.Check
                  type="radio"
                  aria-label="inspeccion"
                  label="Inspección"
                />
                <Form.Check
                  type="radio"
                  aria-label="pretoma"
                  label="Pre toma"
                />
                <Form.Check type="radio" aria-label="visita" label="Visita" />
                <Form.Check type="radio" aria-label="reserva" label="Reserva" />
              </>
            )}
          </div>
          <div className="mt-3">
            <ButtonComponent text={"Guardar"} color={"purple"} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCheckboxComponent;
