import React, { useState } from "react";
import SwitchDisabledEnabledComponent from "./SwitchDisabledEnabledComponent";
import IconDelete from "../images/icon-delete.svg";
import OpeningHoursComponent from "./OpeningHoursComponent";
import AddInspectorsComponent from "./AddInspectorsComponent";
import UserComponent from "./UserComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import ModalDeleteComponent from "../../../components/ModalDeleteComponent";

const EditOnlySlotComponent = ({ setActiveSlot }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [switchValue, setSwitchValue] = useState("");

  return (
    <>
      <ModalDeleteComponent
        isOpen={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        text={"el slot"}
      />
      <div
        className="d-flex align-items-center justify-content-center flex-column"
        style={{ width: "100%" }}
      >
        <div
          className="d-flex align-items-center justify-content-between mt-3"
          style={{ width: "100%" }}
        >
          <SwitchDisabledEnabledComponent
            setSwitchValue={setSwitchValue}
            leftText={"Habilitado"}
            rightText={"Deshabilitado"}
          />
          <button
            className="btn-delete-slot"
            onClick={() => setShowModalDelete(true)}
          >
            <img src={IconDelete} alt="icon-delete" />
            Eliminar slot
          </button>
        </div>
        <div className="divider-card-appointment mt-3 mb-3"></div>
        <OpeningHoursComponent disabled={true} />
        <div className="divider-card-appointment mt-3 mb-3"></div>
        <AddInspectorsComponent />
        <div className="divider-card-appointment mt-1 mb-3"></div>
        <UserComponent name={"Nombre de usuario"} handleDelete={true} />
        <UserComponent name={"Nombre de usuario"} handleDelete={true} />
        <div className="d-flex align-items-center justify-content-center mt-3">
          <ButtonComponent
            text={"Cancelar"}
            color={"white"}
            handleEvent={() => setActiveSlot(false)}
          />
          <ButtonComponent
            text={"Guardar cambios"}
            color={"purple"}
            handleEvent={() => setActiveSlot(false)}
          />
        </div>
      </div>
    </>
  );
};

export default EditOnlySlotComponent;
