import React from "react";
import SelectComponent from "../../../components/SelectComponent";

const OpeningHoursComponent = ({ title, disabled }) => {
  const data_hours = ["9:30", "10:00", "10:30", "11:00"];

  return (
    <div
      className="d-flex align-items-start justify-content-center flex-column"
      style={{ width: "100%" }}
    >
      <p className="label-select">{title ? title : "Horario de atención"}</p>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: "100%" }}
      >
        <SelectComponent
          label={"Horario de atención"}
          options={data_hours}
          width={"222px"}
          disabled={disabled}
        />
        <p className="ms-1 me-1">a</p>
        <SelectComponent
          label={"Horario de atención"}
          options={data_hours}
          width={"222px"}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default OpeningHoursComponent;
