import { signInWithPopup, signOut } from "firebase/auth";
import { types } from "../types/types";
import { auth, googleProvider } from "../../firebase/config";

export const startGoogleLogin = () => {
  return async (dispatch) => {
    const { user } = await signInWithPopup(auth, googleProvider);
    dispatch(login(user.uid, user.displayName, user.email, true));
  };
};

export const login = (uid, displayName, email, isAuthenticated) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
    email,
    isAuthenticated,
  },
});

export const startLogout = (dispatch, navigate) => {
  signOut(auth);
  dispatch(logout(false));
  navigate("/login");
};

export const logout = (isAuthenticated) => ({
  type: types.logout,
  payload: {
    isAuthenticated,
  },
});
