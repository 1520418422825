import React from "react";
import { Modal } from "react-bootstrap";
import ButtonComponent from "../../../components/ButtonComponent";

export const ModalEditHoursComponent = ({ isOpen, onClose }) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered size="md">
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <p className="title-modal">¿Querés modificar {}?</p>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <ButtonComponent
              text={"No"}
              color={"white"}
              style={{ width: "130px" }}
              handleEvent={onClose}
            />
            <ButtonComponent
              text={"Modificar"}
              color={"purple"}
              style={{ width: "130px" }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
