import React from "react";
import "../styles/components/label-input.css"

export const InputComponent = ({
  label,
  placeholder,
  width,
  valueRegister,
  register,
  disabled,
  value,
  required,
}) => {
  return (
    <div className="mb-3" style={{ width: width ? "100%" : "241px" }}>
      <p className="label-input mb-1">{label}</p>
      <input
        className="input-form mb-2 mb-md-0"
        style={{ width: width ? "100%" : "241px" }}
        placeholder={placeholder}
        {...register(`${valueRegister}`, {
          required: required,
        })}
        disabled={disabled}
        value={value}
      />
    </div>
  );
};
