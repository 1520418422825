import React from "react";
import "../styles/components/button.css";

const ButtonComponent = ({ text, color, handleEvent, style }) => {
  return (
    <>
      <button className={`btn-${color}`} onClick={handleEvent} style={style}>
        {text}
      </button>
    </>
  );
};

export default ButtonComponent;
