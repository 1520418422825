import React from "react";
import { useNavigate } from "react-router-dom";
import CardComponent from "../../components/CardComponent";
import "./style/style.css";

const HomePage = () => {
  const navigate = useNavigate();

  const navigateToRoute = (route) => {
    navigate(`/${route}`);
  };

  return (
    <CardComponent title={"Secciones"}>
      <div className="d-flex align-items-center justify-content-center flex-column mt-4">
        <button
          className="mb-3 btn-sections"
          onClick={() => navigateToRoute("turnos")}
        >
          Turnos
        </button>
        <button
          className="mb-3  btn-sections"
          onClick={() => navigateToRoute("inspectores-vendedores")}
        >
          Inspectores / Vendedores
        </button>
        <button
          className="mb-3  btn-sections"
          onClick={() => navigateToRoute("sucursales")}
        >
          Sucursales
        </button>
      </div>
    </CardComponent>
  );
};

export default HomePage;
