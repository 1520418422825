import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import LogoAutaHeader from "../images/logo-auta-header.png";
import "../styles/components/headerStyle.css";
import { useNavigate } from "react-router-dom";
import IconUser from "../images/icon-user.svg";
import IconArrowDown from "../images/icon-arrow-down.svg";
import IconLogout from "../images/icon-logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../redux/actions/auth";

export const HeaderComponent = () => {
  const { isAuthenticated, name } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = useState(false);

  const logOutAndRedirect = () => {
    startLogout(dispatch, navigate);
  };

  const showDropDownLogout = () => {
    setShowDropDown(!showDropDown);
  };

  return (
    <>
      <Container
        style={{
          position: "sticky",
          top: "0",
          zIndex: "4",
        }}
      >
        <div className="container-all">
          <Navbar className="header">
            <Navbar.Brand
              style={{ cursor: "pointer", padding: "0px !important" }}
            >
              <img
                src={LogoAutaHeader}
                alt="logo-auta"
                className="logo-auta-header ms-3 ms-md-4"
              />
            </Navbar.Brand>
            <div
              style={{ width: "100%" }}
              className="d-flex align-items-center justify-content-end"
            >
              {isAuthenticated ? (
                <div
                  onClick={showDropDownLogout}
                  className={`d-flex flex-column align-items-center me-3 me-md-4 ${
                    showDropDown ? "dropdown-logout" : ""
                  }`}
                >
                  <div className="dropdown-user d-flex align-items-center justify-content-between">
                    <img src={IconUser} alt="icon-user" className="icon-user" />
                    <p className="text-user-name ms-1 me-1">{name}</p>
                    <img src={IconArrowDown} alt="arrow-down" />
                  </div>
                  {showDropDown ? (
                    <>
                      <div
                        className="d-flex align-items-center justify-content-center mt-2"
                        onClick={logOutAndRedirect}
                      >
                        <img src={IconLogout} alt="icon-logout" />
                        <p className="text-logout">Logout</p>
                      </div>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          </Navbar>
        </div>
      </Container>
    </>
  );
};
