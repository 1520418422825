import React, { useState } from "react";
import CardComponent from "../../components/CardComponent";
import IconAdd from "./images/icon-add.svg";
import UserComponent from "../Appointment/components/UserComponent";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import "./style/style.css";

const LocationsPage = () => {
  const navigate = useNavigate();
  const [actualScreen, setActualScreen] = useState("initial");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleEdit = (id) => {
    setActualScreen("edit");
  }

  return (
    <CardComponent
      title={
        actualScreen === "add" ? "Agregar Sucursal" :
          actualScreen === "edit" ? "Editar Sucursal" :
            "Sucursales"
      }
      handleClick={
        actualScreen === "add" ? () => setActualScreen("initial") :
          actualScreen === "edit" ? () => setActualScreen("initial") :
            () => navigate("/")
      }
    >
      {actualScreen === "initial" ?
        <div
          className="d-flex flex-column align-items-center justify-content-between mt-3"
          style={{ width: "100%", padding: "5px" }}
        >
          <button
            className="btn-add-inspectors d-flex align-items-center justify-content-center"
            onClick={() => setActualScreen("add")}
          >
            <img src={IconAdd} alt="icon-add" />
            <p>Agregar sucursal</p>
          </button>
          <div className="divider-card-appointment mt-3 mb-3"></div>
          <UserComponent name={"Nombre sucursal"} handleEdit={() => handleEdit(1)} />
          <UserComponent name={"Nombre sucursal"} handleEdit={() => handleEdit(2)} />
        </div>
      :
      (actualScreen === "edit" || actualScreen === "add") ?
        <div
          className="d-flex flex-column align-items-center justify-content-between mt-3"
          style={{ width: "100%", padding: "10px" }}
        >
          <InputComponent
            label={"Nombre de la sucursal"}
            placeholder={"Ingresá el nombre"}
            register={register}
            valueRegister={"name"}
            width={true}
            disabled={false}
            /* value={"email"} */
          />
          <InputComponent
          className="mt-3"
            label={"Dirección de la sucursal"}
            placeholder={"Ingresá la dirección"}
            register={register}
            valueRegister={"location"}
            width={true}
            disabled={false}
            /* value={"email"} */
          />
          <div className="mt-3">
            <ButtonComponent text="Cerrar" color="white" handleEvent={() => setActualScreen("initial")}/>
            <ButtonComponent text={actualScreen === "edit"? "Guardar cambios": "Agregar"} color="purple"/>
          </div>
          {actualScreen === "edit" && <label className="delete-label mt-3">Eliminar sucursal</label>}
        </div>
      : null
      }
    </CardComponent>
  );
};

export default LocationsPage;
