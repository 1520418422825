import React from "react";
import "../styles/components/select.css";
import { colors } from "../styles/colors";

const SelectComponent = ({ label, options, width, disabled }) => {
  const style = {
    width: width,
    backgroundColor: disabled ? colors.purple.utraLightPurple : "white",
  };

  return (
    <>
      <select className="custom-select" style={style} disabled={disabled}>
        {options?.map((data, index) => (
          <option key={index}>{data}</option>
        ))}
      </select>
    </>
  );
};

export default SelectComponent;
