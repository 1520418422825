import React, { useState } from "react";
import CardComponent from "../../components/CardComponent";
import IconAdd from "./images/icon-add.svg";
import UserComponent from "../Appointment/components/UserComponent";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputComponent } from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import "./style/style.css";
import SwitchDisabledEnabledComponent from "../Appointment/components/SwitchDisabledEnabledComponent";
import { Form } from "react-bootstrap";
import OpeningHoursComponent from "../Appointment/components/OpeningHoursComponent";
import { OptionsEditComponent } from "../Appointment/components/OptionsEditComponent";
import SelectComponent from "../../components/SelectComponent";

const InspectorsPage = () => {
  const navigate = useNavigate();
  const [actualScreen, setActualScreen] = useState("initial");
  const [selection] = useState("inspector");
  const [switchValue, setSwitchValue] = useState("");

  const [selectedOption, setSelectedOption] = useState("option1");
  const [weekDays, setWeekDays] = useState({
    Lun: true,
    Mar: true,
    Mie: true,
    Jue: true,
    Vie: true,
    Sab: false,
    Dom: false,
  });
  const { register } = useForm();

  const handleEdit = (id) => {
    setActualScreen("editIns");
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDayClick = (day) => {
    setWeekDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const data_locations = [{ name: "Independencia" }, { name: "Warnes" }];
  const data_agents = [
    "Santiago Bensadon",
    "Giuliano Perrini",
    "Santiago Sosa",
  ];

  console.log("switchValue", switchValue);

  return (
    <CardComponent
      title={
        actualScreen === "add"
          ? "Agregar inspector / vendedor"
          : actualScreen === "editIns" && switchValue === "Inspectores"
          ? "Editar inspector"
          : actualScreen === "editIns" && switchValue === "Vendedores"
          ? "Editar vendedor"
          : "Inspectores / Vendedores"
      }
      handleClick={
        actualScreen === "add" ||
        actualScreen === "editIns" ||
        actualScreen === "editVen"
          ? () => {
              setActualScreen("initial");
              setSwitchValue("Inspectores");
            }
          : () => navigate("/")
      }
    >
      {actualScreen === "initial" ? (
        <div
          className="d-flex flex-column align-items-center justify-content-between mt-3"
          style={{ width: "100%", padding: "5px" }}
        >
          <div className="d-flex flex-row">
            <SwitchDisabledEnabledComponent
              selection={selection === "inspector"}
              leftText="Inspectores"
              rightText="Vendedores"
              setSwitchValue={setSwitchValue}
            />
            <button
              className="btn-add-inspectors d-flex align-items-center justify-content-center"
              style={{ marginLeft: "15px" }}
              onClick={() => setActualScreen("add")}
            >
              <img src={IconAdd} alt="icon-add" />
              <p>Agregar agente</p>
            </button>
          </div>
          <div className="divider-card-appointment mt-3 mb-3" />
          <UserComponent
            name={"Nombre usuario"}
            handleEdit={() => handleEdit(1)}
          />
          <UserComponent
            name={"Nombre usuario"}
            handleEdit={() => handleEdit(2)}
          />
          <UserComponent
            name={"Nombre usuario"}
            handleEdit={() => handleEdit(3)}
          />
          <UserComponent
            name={"Nombre usuario"}
            handleEdit={() => handleEdit(4)}
          />
          <UserComponent
            name={"Nombre usuario"}
            handleEdit={() => handleEdit(5)}
          />
        </div>
      ) : actualScreen === "add" ||
        actualScreen === "editIns" ||
        actualScreen === "editVen" ? (
        <div
          className="d-flex flex-column align-items-center justify-content-between mt-3"
          style={{ width: "100%", padding: "10px" }}
        >
          {actualScreen === "add" && (
            <div className="d-flex flex-column mb-2" style={{ width: "100%" }}>
              <label className="label-input mb-1">
                Seleccionar tipo de Usuario
              </label>
              <div className="d-flex flex-row">
                <Form.Check
                  type="radio"
                  aria-label="radio 1"
                  label="Inspector"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={handleRadioChange}
                />
                <Form.Check
                  style={{ marginLeft: "80px" }}
                  type="radio"
                  aria-label="radio 2"
                  label="Vendedor"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={handleRadioChange}
                />
              </div>
              <div
                className="d-flex flex-column align-items-start jusity-content-center mt-1"
                style={{ width: "100%" }}
              >
                <p className="label-input mb-1">Agentes disponibles</p>
                <SelectComponent
                  label={"Agentes disponibles"}
                  options={data_agents}
                  width={"100%"}
                />
              </div>
              <div className="divider-card-appointment mt-3 mb-3" />
            </div>
          )}
          <OptionsEditComponent
            text={"Seleccionar sucursal"}
            data={data_locations}
          />
          <div className="divider-card-appointment mt-2 mb-3" />
          <div className="d-flex flex-column mb-3" style={{ width: "100%" }}>
            <label className="label-input mb-1">
              Seleccione días laborales
            </label>
            <div className="week-day-div">
              {Object.keys(weekDays).map((key) => {
                return (
                  <div
                    className={`week-day${weekDays[key] ? "" : "-disabled"}`}
                    onClick={() => handleDayClick(key)}
                  >
                    <label style={{ cursor: "pointer" }}>{key}</label>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="divider-card-appointment mt-2 mb-3" />
          <OpeningHoursComponent title="Horarios de atención de Lunes a Viernes" />
          <div className="mb-3" />
          <OpeningHoursComponent title="Horarios de atención de Sabados a Domingos / Feriados" />
          <div className="mt-4">
            <ButtonComponent
              text="Cerrar"
              color="white"
              handleEvent={() => setActualScreen("initial")}
            />
            <ButtonComponent
              text={actualScreen === "edit" ? "Guardar cambios" : "Agregar"}
              color="purple"
            />
          </div>
          {(actualScreen === "editIns" || actualScreen === "editVen") && (
            <label className="delete-label mt-3">Eliminar {switchValue}</label>
          )}
        </div>
      ) : null}
    </CardComponent>
  );
};

export default InspectorsPage;
