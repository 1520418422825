import React, { useState } from "react";
import SwitchDisabledEnabledComponent from "./SwitchDisabledEnabledComponent";
import IconAdd from "../images/icon-add.svg";
import SlotComponent from "./SlotComponent";
import EditOnlySlotComponent from "./EditOnlySlotComponent";
import ButtonComponent from "../../../components/ButtonComponent";

const SlotInspectComponent = ({
  activeSlot,
  setActiveSlot,
  setActiveSlotInspect,
  setSettingsMonth,
  setEditMonth,
  isHoliday,
}) => {
  const [switchValue, setSwitchValue] = useState("");

  const hour_slot = [
    "09:00 - 10:00",
    "10:00 - 11:00",
    "11:00 - 12:00",
    "12:00 - 13:00",
    "13:00 - 14:00",
    "14:00 - 15:00",
    "15:00 - 16:00",
    "16:00 - 17:00",
    "17:00 - 18:00",
  ];

  return (
    <>
      {activeSlot ? (
        <EditOnlySlotComponent setActiveSlot={setActiveSlot} />
      ) : (
        <>
          <div
            className="d-flex align-items-center justify-content-between mt-3"
            style={{ width: "100%" }}
          >
            <SwitchDisabledEnabledComponent
              setSwitchValue={setSwitchValue}
              leftText={
                isHoliday === "No es feriado" ? "Laborable" : "F. Laborable"
              }
              rightText={
                isHoliday === "No es feriado"
                  ? "No Laborable"
                  : "F. No Laborable"
              }
            />
            <button className="btn-add-inspectors d-flex align-items-center justify-content-center">
              <img src={IconAdd} alt="icon-add" />
              <p>Agregar slot</p>
            </button>
          </div>
          <div className="divider-card-appointment mt-3 mb-3"></div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            {hour_slot.map((data, index) => (
              <SlotComponent
                rangeHour={data}
                slotNumber={index + 1}
                setActiveSlot={setActiveSlot}
              />
            ))}
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <ButtonComponent
              text={"Volver"}
              color={"white"}
              handleEvent={() => {
                setActiveSlotInspect(false);
                setSettingsMonth(false);
                setEditMonth(false);
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SlotInspectComponent;
