import React from "react";

export const DescriptionsDayComponent = ({ color, text }) => {
  const styleCircle = {
    backgroundColor: color,
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    marginRight: "5px",
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div style={styleCircle}></div>
      <p className="description-day">{text}</p>
    </div>
  );
};
