import React from "react";
import { useState } from "react";

const SwitchDisabledEnabledComponent = ({
  leftText,
  rightText,
  setSwitchValue,
}) => {
  const [enabled, setEnabled] = useState(true);

  return (
    <div className="d-flex align-items-center justify-content-between switch-enabled-disabled">
      <div
        className={`d-flex align-items-center justify-content-center ${
          enabled ? "switch-enabled" : "switch-text-disabled"
        }`}
        onClick={() => {
          setEnabled(true);
          setSwitchValue(leftText);
        }}
      >
        {leftText}
      </div>
      <div
        className={`d-flex align-items-center justify-content-center ${
          !enabled ? "switch-enabled" : "switch-text-disabled"
        }`}
        onClick={() => {
          setEnabled(false);
          setSwitchValue(rightText);
        }}
      >
        {rightText}
      </div>
    </div>
  );
};

export default SwitchDisabledEnabledComponent;
