import React, { useState } from "react";
import IconRemove from "../images/icon-remove.svg";
import IconEdit from "../images/icon-edit.svg";
import ModalDeleteComponent from "../../../components/ModalDeleteComponent";

const UserComponent = ({ name, handleEdit, handleDelete }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);

  return (
    <>
      <ModalDeleteComponent
        isOpen={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        text={"el inspector"}
      />
      <div className="d-flex align-items-center justify-content-between container-user-name">
        <p className="name-user">{name}</p>
        {handleEdit ? (
          <img
            src={IconEdit}
            alt="icon-remove"
            style={{ cursor: "pointer", color: "red", height: "20px" }}
            onClick={handleEdit}
          />
        ) : (
          <img
            src={IconRemove}
            alt="icon-remove"
            style={{ cursor: "pointer" }}
            onClick={handleDelete ? () => setShowModalDelete(true) : null}
          />
        )}
      </div>
    </>
  );
};

export default UserComponent;
