import React from "react";

const SlotComponent = ({ rangeHour, slotNumber, setActiveSlot }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center flex-column contain-slot"
      onClick={() => {
        setActiveSlot(true);
      }}
    >
      <p className="range-hour-slot">{rangeHour}</p>
      <p className="number-slot">Slot {slotNumber}</p>
    </div>
  );
};

export default SlotComponent;
