import React from "react";
import "../styles/components/card.css";
import IconArrowBack from "../images/arrow-back.svg"

const CardComponent = ({ title, children, handleClick }) => {
  return (
    <>
      <div className="card">
        <div className="container-title-card">
          {handleClick && <img src={IconArrowBack} alt="icon-add" className="back-icon" onClick={handleClick} />}
          <p className="title-card">{title}</p>
        </div>
        {children}
      </div>
    </>
  );
};

export default CardComponent;
