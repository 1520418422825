import React from "react";
import IconAdd from "../images/icon-add.svg";

const AddInspectorsComponent = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{ width: "100%" }}
    >
      <p className="label-select">Inspectores</p>
      <button className="btn-add-inspectors d-flex align-items-center justify-content-center">
        <img src={IconAdd} alt="icon-add" />
        <p className="text-add-inspectors">Agregar inspectores</p>
      </button>
    </div>
  );
};

export default AddInspectorsComponent;
