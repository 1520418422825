import React, { useState } from "react";
import SelectComponent from "../../../components/SelectComponent";
import OpeningHoursComponent from "./OpeningHoursComponent";
import AddInspectorsComponent from "./AddInspectorsComponent";
import UserComponent from "./UserComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import { ModalEditHoursComponent } from "./ModalEditHoursComponent";

const EditDaySlotComponent = ({ setActiveEditDays, setSettingsMonth }) => {
  const data_hours = ["15minutos", "30 minutos", "45 minutos", "1 hora"];
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  return (
    <>
      <ModalEditHoursComponent isOpen={isOpen} onClose={onClose} />
      <OpeningHoursComponent disabled={false} />
      <div className="divider-card-appointment mt-3 mb-3"></div>
      <div
        className="d-flex align-items-start justify-content-center flex-column"
        style={{ width: "100%" }}
      >
        <p className="label-select">Duración del slot</p>
        <SelectComponent options={data_hours} width={"100%"} />
      </div>
      <div className="divider-card-appointment mt-3 mb-3"></div>
      <AddInspectorsComponent />
      <div className="divider-card-appointment mt-1 mb-3"></div>
      <UserComponent name={"Nombre de usuario"} handleDelete={true} />
      <UserComponent name={"Nombre de usuario"} handleDelete={true} />
      <div className="d-flex align-items-center justify-content-center mt-3">
        <ButtonComponent
          text={"Cancelar"}
          color={"white"}
          handleEvent={() => setActiveEditDays(false)}
        />
        <ButtonComponent
          text={"Guardar cambios"}
          color={"purple"}
          handleEvent={() => {
            setActiveEditDays(false);
            setSettingsMonth(false);
          }}
        />
      </div>
    </>
  );
};

export default EditDaySlotComponent;
