import React from "react";
import { OptionsEditComponent } from "./OptionsEditComponent";
import ButtonComponent from "../../../components/ButtonComponent";

export const EditMonthComponent = ({ setSettingsMonth, setEditMonth }) => {
  const data_type = [
    { name: "Inspección" },
    { name: "Pre Toma" },
    { name: "Visita" },
    { name: "Reserva" },
  ];
  const data_locations = [{ name: "Independencia" }, { name: "Warnes" }];
  const data_month = [
    { name: "Enero", enabled: "no" },
    { name: "Febrero", enabled: "no" },
    { name: "Marzo", enabled: "no" },
    { name: "Abril", enabled: "no" },
    { name: "Mayo", enabled: "no" },
    { name: "Junio", enabled: "no" },
    { name: "Julio", enabled: "no" },
    { name: "Agosto", enabled: "no" },
    { name: "Septiembre", enabled: "no" },
    { name: "Octubre", enabled: "si" },
    { name: "Noviembre", enabled: "si" },
    { name: "Diciembre", enabled: "si" },
  ];

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ width: "100%" }}
    >
      <OptionsEditComponent text={"Seleccionar tipo"} data={data_type} />
      <div className="divider-card-appointment mt-3 mb-3"></div>
      <OptionsEditComponent
        text={"Seleccionar sucursal"}
        data={data_locations}
      />
      <div className="divider-card-appointment mt-3 mb-3"></div>
      <OptionsEditComponent text={"Seleccionar mes"} data={data_month} />
      <div className="d-flex align-items-center justify-content-center mt-4">
        <ButtonComponent
          text={"Volver"}
          color={"white"}
          handleEvent={() => setEditMonth(false)}
        />
        <ButtonComponent
          text={"Continuar"}
          color={"purple"}
          handleEvent={() => {
            setSettingsMonth(true);
            setEditMonth(false);
          }}
        />
      </div>
    </div>
  );
};
