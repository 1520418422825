import React from "react";
import { Outlet } from "react-router-dom"; // Importa Outlet para renderizar las rutas anidadas
import "../styles/components/container-app.css";
import { HeaderComponent } from "./HeaderComponent";

const ContainerAppComponent = () => {
  return (
    <>
      <div className="container-all">
        <div style={{ height: "10%" }}>
          <HeaderComponent />
        </div>
        <div
          style={{ height: "90%" }}
          className="d-flex align-items-start justify-content-center"
        >
          <Outlet /> {/* Aquí se renderizarán las rutas anidadas */}
        </div>
      </div>
    </>
  );
};

export default ContainerAppComponent;
