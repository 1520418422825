import React, { useState } from "react";
import SwitchDisabledEnabledComponent from "./SwitchDisabledEnabledComponent";
import EditDaySlotComponent from "./EditDaySlotComponent";

const EditDaysComponent = ({ setActiveEditDays, setSettingsMonth }) => {
  const [switchValue, setSwitchValue] = useState("");

  return (
    <>
      <SwitchDisabledEnabledComponent
        setSwitchValue={setSwitchValue}
        leftText={"Habilitado"}
        rightText={"Deshabilitado"}
      />
      <div className="divider-card-appointment mt-3 mb-3"></div>
      <EditDaySlotComponent
        setActiveEditDays={setActiveEditDays}
        setSettingsMonth={setSettingsMonth}
      />
    </>
  );
};

export default EditDaysComponent;
