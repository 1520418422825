import React, { useState } from "react";
import IconSettings from "../images/icon-settings.svg";
import ModalCheckboxComponent from "../../../components/ModalCheckboxComponent";

const ButtonsHeaderComponent = ({ setEditMonth }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");

  const showModal = (textTitle) => {
    setIsOpen(true);
    setTitle(textTitle);
  };

  return (
    <>
      <ModalCheckboxComponent
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={title}
      />
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: "100%" }}
      >
        <button
          className="btn-header-appointment"
          onClick={() => showModal("tipo de turno")}
        >
          Inspección
        </button>
        <button
          className="btn-header-appointment"
          onClick={() => showModal("sucursal")}
        >
          Independencia
        </button>
        <button
          className="btn-header-appointment"
          onClick={() => {
            setEditMonth(true);
          }}
        >
          Configurar mes{" "}
          <img
            src={IconSettings}
            alt="icon-settings"
            className="icon-settings"
          />
        </button>
      </div>
    </>
  );
};

export default ButtonsHeaderComponent;
